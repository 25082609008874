import React, { useState, useMemo, useCallback } from 'react';
import { Box, Flex, Text } from '@qga/roo-ui/components';
import LocationAutocompleter from 'components/LocationAutocompleter';
import OccupantPicker from 'components/OccupantPicker';
import AvailabilityDatePicker from 'components/AvailabilityDatePicker/DatePicker';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { useDataLayer } from 'hooks/useDataLayer';
import capitalize from 'lodash/capitalize';
import QffWidget from 'components/QffWidget/QffWidget';
import { useIsAuthenticated } from 'lib/oauth';
import { AirbnbTab as AirbnbTabProps } from 'types/content';
import useAirbnbRedirect from 'components/AirbnbPage/AirbnbRedirect/useAirbnbRedirect';
import { SubmitButton, LoginContainer } from './Airbnb.style';
import { useSelector, useDispatch } from 'react-redux';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { updateQuery } from 'store/search/searchActions';
import { getQueryParams } from 'store/router/routerSelectors';
import { addDays } from 'lib/date';

type SearchQueryProps = {
  location?: string;
  checkIn?: string;
  checkOut?: string;
  adults?: number;
  children?: number;
  infants?: number;
  payWith?: string;
  id?: number;
};

type ErrorPayload = {
  message?: string;
};

type SearchErrorProps = {
  location?: ErrorPayload;
  dates?: ErrorPayload;
};

const mapQueryToGAPayload = (query) =>
  Object.keys(query)
    .map((inputType) => {
      switch (inputType) {
        case 'location':
          return { type: 'Location Search', value: `${query.location} Selected` };
        case 'checkIn':
          return { type: 'Date Calendar', value: 'Checkin Date Selected' };
        case 'checkOut':
          return { type: 'Date Calendar', value: 'Checkout Date Selected' };
        case 'adults':
          return { type: 'Guests Dropdown', value: `Guests Adults ${query.adults} Selected` };
        case 'children':
          return { type: 'Guests Dropdown', value: `Guests Children ${query.children} Selected` };
        case 'infants':
          return { type: 'Guests Dropdown', value: `Guests Infants ${query.infants} Selected` };
        case 'payWith':
          return { type: 'Points and Cash Toggle', value: `${capitalize(query.payWith)} Selected` };
        default:
          return null;
      }
    })
    .filter(Boolean);

const AirbnbTab = ({ isLandingPage }: AirbnbTabProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const { isLessThanBreakpoint, isGreaterThanOrEqualToBreakpoint } = useBreakpoints();
  const dispatch = useDispatch();
  const today = new Date();
  const isMobile = isLessThanBreakpoint(0);
  const isAuthenticated = useIsAuthenticated();
  const query = useSelector(getQueryParams);
  const [searchQuery, setSearchQuery] = useState<SearchQueryProps>({
    location: query.location ? query.location : null,
    checkIn: query.checkIn ? query.checkIn : today,
    checkOut: query.checkOut ? query.checkOut : addDays(today, 1),
    adults: query.adults ? Number(query.adults) : 2,
    children: query.children ? Number(query.children) : 0,
    infants: query.infants ? Number(query.infants) : 0,
  });
  const [searchError, setSearchError] = useState<SearchErrorProps>({});
  const { location, checkIn, checkOut, adults, children, infants } = searchQuery;
  const checkInDate = checkIn ? new Date(checkIn) : undefined;
  const checkOutDate = checkOut ? new Date(checkOut) : undefined;
  const [selectedDates, setSelectedDates] = useState({ startDate: checkInDate, endDate: checkOutDate });
  const selectedOccupants = useMemo(() => ({ adults, children, infants }), [adults, children, infants]);
  const { handleAirbnbRedirect } = useAirbnbRedirect();

  const getLimitCharacter = () => {
    let limit;

    if (isLessThanBreakpoint(0)) {
      limit = 27;
    } else if (isLessThanBreakpoint(1) && isGreaterThanOrEqualToBreakpoint(0)) {
      limit = 100;
    } else if (isLessThanBreakpoint(2) && isGreaterThanOrEqualToBreakpoint(1)) {
      limit = 30;
    } else {
      limit = 35;
    }
    return limit;
  };

  const onUpdateQuery = (payload) => {
    setSearchQuery({ ...searchQuery, ...payload });

    const queryEvents = mapQueryToGAPayload(payload);
    queryEvents.forEach((gaPayload) => {
      emitInteractionEvent(gaPayload);
    });
  };

  const onSubmitQuery = () => {
    const { location, adults = 0, children = 0, infants = 0, checkIn: checkin, checkOut: checkout } = searchQuery;
    const guests = adults + children + infants;
    const searchQueryString = stringifyQueryValues({ location, guests, checkin, checkout });

    if (!isAuthenticated) emitInteractionEvent({ type: 'CTA Clicked', value: 'Continue to log in' });

    const payload = {
      location: searchQuery.location,
      checkIn: searchQuery.checkIn,
      checkOut: searchQuery.checkOut,
      adults: searchQuery.adults,
      children: searchQuery.children,
      infants: searchQuery.infants,
    };
    dispatch(updateQuery(payload));
    handleAirbnbRedirect(searchQueryString);
  };

  const onRouteToLocation = (payload) => {
    if (searchError) {
      setSearchError({});
    }
    onUpdateQuery({ ...payload });
  };

  const onClickOutside = useCallback(() => {
    setSelectedDates({ startDate: undefined, endDate: undefined });
  }, [setSelectedDates]);

  return (
    <>
      <Box>
        {!isMobile && !isLandingPage && (
          <Text fontSize="md" fontWeight="bold" display={['block']} pb={4}>
            Book an Airbnb and earn 1 Qantas Point per A$1 spent^
          </Text>
        )}
        {isMobile && !isLandingPage && (
          <Text fontSize="md" fontWeight="bold" display={['block']} pb={4}>
            Earn 1 Qantas Point per A$1 spent^
          </Text>
        )}
      </Box>
      <Flex alignItems="center" flexWrap="wrap">
        <Box pr={[0, 0, 4]} width={['100%', '100%', '35%']} order={1}>
          <LocationAutocompleter
            title="Where would you like to go?"
            label="Where would you like to go?"
            locationName={location}
            labelOptions={{ color: 'greys.alto' }}
            updateQuery={onRouteToLocation}
            placeholder="Enter a destination"
            error={!!searchError?.location}
            returnProperties={false}
            isOptional={true}
            limit={getLimitCharacter()}
          />
        </Box>

        <Box pr={[0, 0, 4]} pt={[4, 4, 0]} width={['100%', '100%', '40%']} order={[3, 2]} data-testid="stay-date-picker">
          <AvailabilityDatePicker
            selectedDates={selectedDates}
            labelOptions={{ color: 'greys.alto' }}
            updateQuery={onUpdateQuery}
            clearSelectedDates={onClickOutside}
            anchorX="right"
            isLimited={true}
            isOptional={true}
          />
        </Box>

        <Box pt={[4, 4, 0]} width={['100%', '100%', '25%']} order={[4, 3]} data-testid="occupant-picker">
          <OccupantPicker
            occupants={selectedOccupants}
            labelOptions={{ color: 'greys.alto' }}
            updateQuery={onUpdateQuery}
            viewThreshold={0}
            verboseInMobile
            multiroomEnabled={false}
          />
        </Box>

        <LoginContainer flexDirection={['column-reverse', 'row', 'row']} mt={[4, 6, 6]} order={isMobile ? 7 : 6}>
          <QffWidget />
        </LoginContainer>
        <Box width={['100%', '50%', '25%']} mt={[4, 5, 5]} order={isMobile ? 6 : 7}>
          <SubmitButton
            variant="primary"
            onClick={onSubmitQuery}
            aria-label="Search Airbnb"
            width="100%"
            data-testid="search-airbnb-cta"
            type="button"
          >
            {isAuthenticated ? 'Search Airbnb' : 'Continue to log in'}
          </SubmitButton>
        </Box>
      </Flex>
    </>
  );
};

export default AirbnbTab;
