import { AIRBNB_INTERSTITIAL_PAGE_URL, HOTELS_URL } from 'config';
import { useIsAuthenticated } from 'lib/oauth';
import { useOAuthState } from 'lib/oauth/useOAuthState';
import { useLoginUrl } from 'lib/qffAuth';
import logger from './../../../server/lib/logger';
import { useRouter } from 'next/router';

export default function useAirbnbRedirect() {
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const { loginUrl } = useLoginUrl();
  const [state] = useOAuthState();

  const handleAirbnbRedirect = (query: string) => {
    if (!isAuthenticated) {
      const url = JSON.stringify(`/airbnb/redirect?${query}`);
      window.localStorage.setItem(state, url);
      router.push(loginUrl);
    } else {
      try {
        router.push(`${AIRBNB_INTERSTITIAL_PAGE_URL}?${query}`);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.errorMessage : error.message;
        logger.warn({ event_name: 'airbnb-redirect-failure', error: errorMessage });
        router.push(`${HOTELS_URL}?searchType=airbnb`);
      }
    }
  };

  return { handleAirbnbRedirect };
}
